// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyAEPecBA9Y140obPIIse1qbFZ8Z9vgewTE",
  authDomain: "gasbillnew5.firebaseapp.com",
  databaseURL: "https://gasbillnew5-default-rtdb.firebaseio.com",
  projectId: "gasbillnew5",
  storageBucket: "gasbillnew5.appspot.com",
  messagingSenderId: "534778604645",
  appId: "1:534778604645:web:6784d69391967df3f12a8c",
  measurementId: "G-PSYPQ6YL5R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };